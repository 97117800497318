/**
 * @readonly
 * @enum {string}
 */
export const FILE_SALARY_DISTRIBUTION = {
  IDLE: "IDLE",
  STARTED: "STARTED",
  UPLOADING: "UPLOADING",
  UPLOADED: "UPLOADED",
  ABORT_UPLOADED: "ABORT_UPLOADED",
  UPLOAD_ERRORED: "UPLOAD_ERRORED",
  ABORT_AUTHORIZATION: "ABORT_AUTHORIZATION",
  CONFIRMATION: "CONFIRMATION",
  CONFIRMED: "CONFIRMED",
  AUTHORIZATION_ERROR: "AUTHORIZATION_ERROR",
  DISTRIBUTING: "DISTRIBUTING",
  DISTRIBUTED: "DISTRIBUTED",
  REJECTED: "REJECTED",
}

export const getStepNumberFromFileSalaryDistributionState = (
  uploadFileState
) => {
  switch (uploadFileState) {
    case FILE_SALARY_DISTRIBUTION.STARTED:
    case FILE_SALARY_DISTRIBUTION.UPLOADING:
    case FILE_SALARY_DISTRIBUTION.UPLOADED:
    case FILE_SALARY_DISTRIBUTION.ABORT_UPLOADED:
    case FILE_SALARY_DISTRIBUTION.UPLOAD_ERRORED:
      return 0

    case FILE_SALARY_DISTRIBUTION.CONFIRMATION:
    case FILE_SALARY_DISTRIBUTION.CONFIRMED:
    case FILE_SALARY_DISTRIBUTION.DISTRIBUTING:
    case FILE_SALARY_DISTRIBUTION.DISTRIBUTED:
    case FILE_SALARY_DISTRIBUTION.REJECTED:
    case FILE_SALARY_DISTRIBUTION.ABORT_AUTHORIZATION:
    case FILE_SALARY_DISTRIBUTION.AUTHORIZATION_ERROR:
      return 1

    case FILE_SALARY_DISTRIBUTION.IDLE:
    default:
      return -1
  }
}

export const CSV_FILE_TABLE_COLUMNS = [
  "web_c_employees_payroll_distributesalary_excelmodal_previewstep_number_label",
  "web_c_employees_payroll_distributesalary_excelmodal_previewstep_name_label",
  "web_c_employees_payroll_distributesalary_excelmodal_previewstep_type_label",
  "web_c_employees_payroll_distributesalary_excelmodal_previewstep_salary_label",
]

export const MAP_EMPLOYEE_TYPE_TO_LOKALISE_KEY = {
  EMPLOYEE:
    "web_c_employees_payroll_distributesalary_excelmodal_previewstep_employed_label",
  NON_EMPLOYEE_USER:
    "web_c_employees_payroll_distributesalary_excelmodal_previewstep_unemployed_label",
}

export const FILE_RECOVERABLE_ERROR_CODES = [
  "DUPLICATED_PAYEES_FOUND",
  "PAYEES_ALREADY_PAID",
  "BALANCE_TOO_LOW",
  "DISTRIBUTION_CURRENCY_MISMATCH",
  "NOT_ALLOWED_CURRENCY",
]

// FIXME this mapping should be removed when Backend returns
// the error messages instead of setting it ourselves
/**
 * @constant
 * @readonly
 */
export const INCORRECT_FILE_CONTENT_ERROR_CODE = "INCORRECT_FILE_CONTENT"
export const WRONG_CSV_HEADERS_ERROR_CODE = "WRONG_CSV_HEADERS"
export const DISTRIBUTION_LIMITATION = "DISTRIBUTION_LIMITATION"

export const MAP_PAYROLL_CSV_ERROR_CODE_TO_MESSAGE = {
  [WRONG_CSV_HEADERS_ERROR_CODE]: "web_c_csvfile_wrongheader_error_message",
  [INCORRECT_FILE_CONTENT_ERROR_CODE]:
    "web_c_csvfile_wrongcontent_error_message",
  [DISTRIBUTION_LIMITATION]:
    "web_c_transfer_bulktransfer_limitexceed_error_text",
}
