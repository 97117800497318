import * as yup from "yup"
import {
  ALPHANUMERIC_REGEX,
  PASSWORD_ERROR_SHORT_PASSWORD,
  PASSWORD_ERROR_MISSING_UPPERCASE,
  PASSWORD_ERROR_MISSING_LOWERCASE,
  PASSWORD_ERROR_MISSING_SPECIAL_CHAR,
  PHONE_NUMBER_REGEX,
} from "../general"

/**
 * @readonly
 * @enum {string}
 *
 *
 *                   ┌─ ◄──── ◄──── ◄───┐
 *                   │                  │
 *                   ▼                  │
 * ┌──────┐     ┌─────────┐     ┌───────┴─────────┐     ┌──────────────┐
 * │ IDLE ├────►│ STARTED ├────►│ INFORMATION_SET ├────►│ PASSWORD_SET │
 * └──────┘     └────┬────┘     └───────┬─────────┘     └───────┬──────┘
 *    ▲              │                  │                       │
 *    │              │                  │                       │
 *    ├─ ◄──── ◄─────┘                  │                       │
 *    ├─ ◄──── ◄──── ◄──── ◄──── ◄──────┘                       │
 *    ├─ ◄──── ◄──── ◄──── ◄──── ◄───── ◄──── ◄──── ◄──── ◄─────┤
 *                                                              │
 *    ▲                                                         ▼
 *    │                                                 ┌──────────────┐
 *    │                               ┌───► ────► ─────►│  AUTHORIZING │
 *    │                               │                 └───────┬──────┘
 *    ▲                               ▲                         │
 *    │                  ┌── ◄────── ─┼─ ◄────── ─┬─ ◄──────────┴───────► ─┬─ ────► ────► ────► ────┐
 *    │                  │            ▲           │                        │                        │
 *    ▲                  │            │           │                        │                        │
 *    │                  ▼            │           ▼                        ▼                        ▼
 *    │          ┌──────────────┐     ▲   ┌──────────────┐         ┌──────────────┐         ┌──────────────┐
 *    ▲          │    ERRORED   │     └───┤   ABORTING   │         │   REJECTED   │         │  AUTHORIZED  │
 *    │          └───────┬──────┘         └───────┬──────┘         └───────┬──────┘         └───────┬──────┘
 *    │                  │                        │                        │                        │
 *    ▲                  │                        │                        │                        │
 *    │                  ▼                        ▼                        ▼                        ▼
 *    │                  │                        │                        │                ┌──────────────┐
 *    ▲                  │                        │                        │                │   CREATING   │
 *    │                  ▼                        ▼                        ▼                └───────┬──────┘
 *    │                  │                        │                        │                        │
 *    ▲                  │                        │                        │                        │
 *    │                  ▼                        ▼                        ▼                        ▼
 *    │                  │                        │                        │                ┌──────────────┐
 *    └─ ◄──── ◄──── ◄───┘ ◄──── ◄──── ◄──── ◄────┘ ◄──── ◄──── ◄──── ◄────┘ ◄─── ◄──── ◄───┤    CREATED   │
 *                                                                                          └──────────────┘
 *
 */
export const TERMINAL_CREATION_STATE = {
  IDLE: "IDLE",
  STARTED: "STARTED",
  INFORMATION_SET: "INFORMATION_SET",
  PASSWORD_SET: "PASSWORD_SET",
  AUTHORIZING: "AUTHORIZING",
  ABORTING: "ABORTING",
  REJECTED: "REJECTED",
  AUTHORIZED: "AUTHORIZED",
  ERRORED: "ERRORED",
  CREATING: "CREATING",
  CREATED: "CREATED",
}

export const getStepNumberFromTerminalCreationState = (
  terminalCreationState
) => {
  switch (terminalCreationState) {
    case TERMINAL_CREATION_STATE.STARTED:
      return 0
    case TERMINAL_CREATION_STATE.INFORMATION_SET:
    case TERMINAL_CREATION_STATE.PASSWORD_SET:
      return 1
    case TERMINAL_CREATION_STATE.AUTHORIZING:
    case TERMINAL_CREATION_STATE.ABORTING:
    case TERMINAL_CREATION_STATE.REJECTED:
    case TERMINAL_CREATION_STATE.AUTHORIZED:
    case TERMINAL_CREATION_STATE.CREATING:
    case TERMINAL_CREATION_STATE.CREATED:
      return 2
    case TERMINAL_CREATION_STATE.IDLE:
    default:
      return -1
  }
}

export const PHONE_NUMBER_USAGE_CHECK_FAILED = "PHONE_NUMBER_USAGE_CHECK_FAILED"
export const PHONE_NUMBER_USED_BEFORE = "PHONE_NUMBER_USED_BEFORE"

export const createTerminalFirstStepSchema = yup.object({
  name: yup
    .string()
    .matches(
      ALPHANUMERIC_REGEX,
      "web_c_terminals_createterminalmodal_namevalidation1_label"
    )
    .min(1, "web_c_terminals_createterminalmodal_namevalidation2_label")
    .max(30, "web_c_terminals_createterminalmodal_namevalidation3_label")
    .required("web_c_terminals_createterminalmodal_namevalidation4_label"),
  displayName: yup
    .string()
    .test(
      "display name pattern",
      "web_c_terminals_createterminalmodal_displayname_validation1_label",
      (displayName) => {
        if (displayName && displayName?.length > 0) {
          return ALPHANUMERIC_REGEX.test(displayName)
        }

        return true
      }
    )
    .test(
      "maximum length",
      "web_c_terminals_createterminalmodal_displayname_validation3_label",
      (displayName) => {
        if (displayName && displayName?.length > 0) {
          return displayName?.length < 31
        }

        return true
      }
    ),
  accountCurrencies: yup
    .object()
    .nullable()
    .required("general_required_field_validation_text"),
  phoneNumber: yup
    .string()
    .required(
      "web_c_terminals_createterminalmodal_phonenumbervalidation2_label"
    )
    .max(10, "web_c_terminals_createterminalmodal_phonenumbervalidation1_label")
    .matches(
      PHONE_NUMBER_REGEX,
      "web_c_terminals_createterminalmodal_phonenumbervalidation1_label"
    ),
  repeatedPhoneNumber: yup
    .string()
    .test(
      "matchesPhoneNumber",
      (value, context) =>
        value === context.parent.phoneNumber ||
        context.createError({
          message:
            "web_c_terminals_createterminalmodal_phonenumbervalidation4_label",
          type: "PHONE_NUMBER_MISMATCH",
        })
    )
    .required(
      "web_c_terminals_createterminalmodal_phonenumbervalidation4_label"
    ),
})

export const MAP_CREATE_TERMINAL_PASSWORD_ERROR_TO_LOKALISE_KEY = [
  {
    errorType: PASSWORD_ERROR_SHORT_PASSWORD,
    lokaliseKey:
      "web_c_terminals_createterminalmodal_password_mincharvalidation_label",
  },
  {
    errorType: PASSWORD_ERROR_MISSING_UPPERCASE,
    lokaliseKey: "web_c_terminals_createterminalmodal_uppercase_validation",
  },
  {
    errorType: PASSWORD_ERROR_MISSING_LOWERCASE,
    lokaliseKey: "web_c_terminals_createterminalmodal_lowercase_validation",
  },
  {
    errorType: PASSWORD_ERROR_MISSING_SPECIAL_CHAR,
    lokaliseKey:
      "web_c_terminals_createterminalmodal_shouldhavespecialchar_validation",
  },
]

export const CREATE_TERMINAL_CURRENCY_DROPDOWN_OPTIONS = [
  {
    id: "IQD",
    text: "general_iqd_label",
  },
  {
    id: "USD",
    text: "general_usd_label",
  },
]
